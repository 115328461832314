(function(){
  var triggers = document.querySelectorAll('.field-name-field-tytu-zwijarki');

  triggers.forEach(function(trigger) {
    var container = trigger.parentNode.querySelector('.field-name-field-kontent-rozwijany');

    trigger.addEventListener('click', function() {
      if(trigger.classList.contains('open')){
        trigger.classList.remove('open');
        container.classList.remove('open');
        return
      }

      trigger.classList.add('open');
      container.classList.add('open');
    })
  })

})();
