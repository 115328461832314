jQuery(document).ready(function($) {
    if(parseInt($('.l-secondary-nav').css("top")) <= -40){
        $('.l-secondary-nav').css({"background-color": "rgba(109, 201, 240, 0.7)"});
    }
    $('.l-secondary-nav').css("opacity","1");

    if($(".l-sidebar-secondary").parent()[0]){
        if($(".l-sidebar-secondary").parent()[0].className.split(" ").indexOf("l-main-container") <= 0){
            var x = $(".l-sidebar-secondary").clone();
            $(".l-sidebar-secondary").remove();
            $('.l-main-container').append(x);
        }
    }

    $('.kierunki__alphabetong').each(function() {
       if(($(this).find("ul").find('li').length + 1) >= 10) {
            $(this).find("ul").addClass("twocolumns");
       }
    });

    $("#block-views-top-slideshow-block").find(".homeslider__image").each(function() {
        $(this).parent().css("background-image", "url('"+$(this).find("img").attr("src")+"')");
        $(this).find("img").parent().remove();
    });

    $("#block-views-top-slideshow-block").find(".homeslider__copy").each(function() {
      var target = $(this).find(".hometitle__link").attr("href");
      var length = $(".homeslider--slide").size();
      $(this).before("<a class=\"link\" href=\""+target+"\"></a>");
      for(var i=0; i<length ; i++){
        $(".link")[i].appendChild($('.homeslider__copy')[i]);
      }
    });

    $(".page-node-78").find(".main-content").each(function() {
      $(this)[0].appendChild($("#block-views-og-szkola-content-block-4")[0]);
    });

    function callTo() {
      if (matchMedia('only screen and (max-width: 639px)').matches) {
        $("#navbar").find(".phone").prepend("<a class=\"callto\" href=\"tel:800800188\"></a>");
        $(".callto")[0].appendChild($(".icon")[0]);
        $(".menu-name-main-menu").find("> .menu").prepend("<a class=\"zapisy\" href=\"/rekrutacja\">ZAPISY DO SZKÓŁ I NA KURSY</a>");
        $(".menu-name-main-menu").find("> .menu").prepend("<a class=\"callto\" href=\"tel:800800188\"><div class=\"infolinia\"></div></a>");
      }
    }
    callTo();

    if($(".l-decoarea").find("img").length > 0){
        if($(".l-decoarea").find(".map").length <= 0){
            var img = $(".l-decoarea").find("img").attr("src");
            $(".l-decoarea").find("img").remove();
            $(".l-decoarea").css("background-image", "url('"+img+"')");
        } else {
            $(".l-decoarea").addClass("noresize");
        }
    }

    $('.messages.status').delay(3000).slideUp(300);


    $('.alphabetong__uberwrap').masonry({
        gutter: 10
    });

    function gallery() {
var scroller = $('#block-views-top-slideshow-block');
        var scrollerContent = scroller.children('ul.homeslider');
        var childs = scrollerContent.find("li").length+1;
        var cur = 1;
        var loop;
        scrollerContent.find("li").fadeOut(300);

        function loop(){
            if(cur == childs){
                cur = 1;
                scrollerContent.find("li:nth-child("+(childs-1)+")").fadeOut(300);
            } else {
                scrollerContent.find("li:nth-child("+(cur-1)+")").fadeOut(300);
            }
            scrollerContent.find("li:nth-child("+cur+")").fadeIn(300);
            cur++;
        }
        loop();
        if( $(".homeslider--slide").size()>1 ) {
          loop = setInterval(loop, 5000);
        }
    }

    gallery();
    $(window).load(function() {
        if($("#page_fullscreen_gallery").length > 0) {
            $('.hide').removeClass("hide");
        }
    });
    $('#block-views-mapa-promocji-i-ofert-block').find('img.leaflet-marker-icon').attr('src', '/sites/all/themes/medica/img/marker-icon-red.png');

    function dataGodzina(){
      var start = $('.date-display-start').html();
      var end = $('.date-display-end').html();
      var str = "<div class=\"date-display-single\">"+start + " - " + end+"</div>";
      $('.view-item-kalendarz_zajec').find('.date-display-single').replaceWith(str);
    }
    dataGodzina();

    //function galleryRebuild(){
    //    var rel = $(".views-row").children(".views-field-field-paragraf-zdjecie").children(".colorbox").attr("rel");
    //    $(".views-row").find(".views-field-field-gallery-cover img").wrap('<a rel="'+ rel +'"></a>');
    //
    //}
    //galleryRebuild();

    $(".navbar-collapse > nav").addClass("l-main-navigation");
    $(".navbar-collapse > nav .menu-block-wrapper").addClass("menu-border");
    $(".navbar-collapse > nav .menu-block-wrapper").prepend("<div class=\"exit\"><i class=\"fa fa-times\"></i></div>");

    $(".field-slideshow-controls .prev").html("< Poprzednie");
    $(".field-slideshow-controls .next").html("Następne >");


    //$(".promocje__single").each(function(){
    //    var maxHeight = -1;
    //
    //    $(this).find( $('.promotion-schools') ).each(function() {
    //        maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
    //    });
    //
    //    $(this).find( $('.promotion-schools') ).each(function() {
    //        $(this).height(maxHeight);
    //    });
    //});
    $(document).ready(function(){
        $('h2.block-title').each(function(){
            if( $(this).text() == 'Oferta Edukacyjna' ){
                $(this).parent().addClass('block-oferta-edukacyjna');
            }
        });
    });

    $(document).ready(function(){
      var lastUrl = window.location.pathname.split('/');
      if( lastUrl[lastUrl.length - 1] === 'rekrutacja' ){
        $('.field-name-big-button-register-bottom').remove();
      }
    });






});
