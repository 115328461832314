(function ($) {

    $(document).ready(function () {
        var pull = $('.toggle-nav');
        menu = $('.menu-border');
        menuHeight = menu.height();
        exitbutton = menu.find(".exit");

        $(pull).click(function (e) {
            e.preventDefault();
            if (menu.find(".social").length < 1) {
                $("#navbar").find(".social").clone().appendTo($('.menu-border > ul.menu'));
            }
            if (menu.hasClass("active")) {
                menu.removeClass("active");
            } else {
                menu.addClass("active");
            }
        });
        $(exitbutton).click(function (e) {
            e.preventDefault();
            menu.removeClass("active");
        });


        var firstsidebaropen = false;
        var secondsidebaropen = false;
        if ($(".toggle-nav").css("display") == "block") {
            $('body').prepend("<aside class='l-sidebar-primary-mobile'><span class='toggler-left'></span></aside>");
            $('body').prepend("<aside class='l-sidebar-secondary-mobile'><span class='toggler-right'></span></aside>");

            $("aside.l-sidebar-primary").find('.block').clone().appendTo("aside.l-sidebar-primary-mobile");
            $("aside.l-sidebar-secondary").find('.block').clone().appendTo("aside.l-sidebar-secondary-mobile");
        }
        // $(".toggle-firstsidebar").click(function (e) {
        //     e.preventDefault();
        //     $(this).addClass("active");
        //     $(".l-sidebar-primary-mobile").stop().animate({"left": "0%"}, 300);
        //     $('body').css({"position": "fixed", "overflow": "hidden", "width": "100%", "height": "100%"});
        //     $('body').stop().animate({"left": "100%"}, 300);
        // });
        $(".toggler-left").click(function (e) {
            e.preventDefault();
            $(".toggle-firstsidebar").removeClass("active");
            $(".l-sidebar-primary-mobile").stop().animate({"left": "-100%"}, 300);
            $('body').stop().animate({"left": "0%"}, 300, function () {
                $('body').css({
                    "position": "static",
                    "left": "auto",
                    "overflow": "auto",
                    "width": "auto",
                    "height": "auto"
                });
            });
        });
        $(".toggle-secondsidebar").click(function (e) {
            e.preventDefault();
            $(this).addClass("active");
            $(".l-sidebar-secondary-mobile").stop().animate({"right": "0%"}, 300);
            $('body').css({"position": "relative"});
            $('body').stop().animate({"right": "100%"}, 300);
        });
        $(".toggler-right").click(function (e) {
            e.preventDefault();
            $(".toggle-secondsidebar").removeClass("active");
            $(".l-sidebar-secondary-mobile").stop().animate({"right": "-100%"}, 300);
            $('body').stop().animate({"right": "0%"}, 300, function () {
                $('body').css({"position": "static"});
                $('body').css({"right": "auto"});
            });
        });
        if (matchMedia('only screen and (max-width: 639px)').matches) {
            var secondaryTitle = $('.l-sidebar-secondary-mobile').find('.block-title').first().text();
            var primaryTitle = $('.l-sidebar-primary-mobile').find('.block-title').first().text();
            // $('.toggle-firstsidebar').append(primaryTitle);
            // $('.toggle-secondsidebar').prepend(secondaryTitle);
        }

        if( $(window).width() < 640 ){
            $('.facebook-dropdown__toggle a').on('click touch', function(e){
                e.preventDefault();
                $('.facebook-dropdown').toggleClass('open');
            });
        }

    });

    $(window).resize(function () {
        var w = $(window).width();
        if (w > 320 && menu.is(':hidden')) {
            menu.removeAttr('style');
        }
    });

})(jQuery);

// organic menu group extract

(function ($) {
    $(document).ready(function () {
        var urlArray = window.location.pathname.split('/');
        var urlElement = urlArray[2];
        $("#block-menu-menu-oferta-edukacyjna-stargard .menu a[href*=\\/" + urlElement + "]").addClass("active-trail active").parent().addClass("active-trail");
        $("#block-menu-menu-oferta-edukacyjna-szczecin .menu a[href*=\\/" + urlElement + "]").addClass("active-trail active").parent().addClass("active-trail");
        $("#block-menu-menu-oferta-edukacyjna-winouj-ci .menu a[href*=\\/" + urlElement + "]").addClass("active-trail active").parent().addClass("active-trail");
    });
})(jQuery);


(function ($) {
    $(document).ready(function () {
        var windowWidth = $(window).width();
        if (windowWidth < 640) {
            var counter = 0;
            $(".menu .expanded span").click(function () {
                if (counter == 0) {
                    $(this).siblings(".menu").addClass("open");
                    counter += 1;
                }
                else {
                    $(this).siblings(".menu").removeClass("open");
                    counter = 0;
                }

            });

            //exception

            $('.menu-mlid-489 > a').click(function(e){
                e.preventDefault();
                $(this).siblings(".menu").toggleClass("open");
            });

        }

        if( windowWidth < 640 ){
            if( $('body').hasClass('.node-type-sz-kukie') || $('body').hasClass('node-type-sz-strona') ){
                $('.l-main.main-content').before( $('a.toggle-firstsidebar') );
                $('.l-sidebar-primary-mobile #block-menu-block-4').remove();
                $('.l-sidebar-primary-mobile #block-views-og-szkola-content-block-6').remove();
                $('.toggle-firstsidebar').html($('.toggle-firstsidebar').children());
                var primaryTitle = $('.l-sidebar-primary-mobile').find('.block-title').first().text();
                $('.toggle-firstsidebar').append(primaryTitle);
            }
        }


    });
})(jQuery);

(function ($) {
    $(document).ready(function () {
      if( window.width < 640 ){
        $('.facebook-dropdown__toggle').on('click touch', function() {
          $('.facebook-dropdown').toggleClass('open');
        })
      }
    });
})(jQuery);

// responsive colorbox
(function ($, Drupal, window, document, undefined) {
//Configure colorbox call back to resize with custom dimensions
    $.colorbox.settings.onLoad = function () {
        colorboxResize();
    }

    //Customize colorbox dimensions
    var colorboxResize = function (resize) {
        var width = "90%";
        var height = "90%";

        if ($(window).width() > 960) {
            width = "860"
        }
        if ($(window).height() > 700) {
            height = "630"
        }

        $.colorbox.settings.height = height;
        $.colorbox.settings.width = width;

        //if window is resized while lightbox open
        if (resize) {
            $.colorbox.resize({
                'height': height,
                'width': width
            });
        }
    }

    //In case of window being resized
    $(window).resize(function () {
        colorboxResize(true);
    });


})(jQuery, Drupal, this, this.document);
